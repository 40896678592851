import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import useStyles from './style';
import logo from '../images/bdi-logo.webp';

import useMediaQuery from '@mui/material/useMediaQuery';

const Navbar = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:600px)');


    return (
        <div>
            <AppBar position="fixed" style={{backgroundColor: '#121212'}}>
                <Toolbar>
                    {/* <object data="your-svg-image.svg" type="image/svg+xml"> */}
                    <a href="https://bignet.id/">
                        { matches === true ?
                            <img src={logo} alt="logo" className={classes.logo}/>
                            :
                            <img src={logo} alt="logo" className={classes.logoMin}/>

                        }
                    </a>
                    {/* </object> */}
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Navbar;