import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cardSecondary: {
        // margin: theme.spacing(2),
        marginTop: '5vh',
        marginButtom: '5vh',
        padding: '5vh',
        backgroundColor: '#10325F',
        borderRadius: 0
    },
    image: {
        maxWidth: '15vh',
    },
    navigationLink: {
        textTransform: 'none'
    },
    heading: {
    //   fontSize: 'calc(80% + 0.2vw)'
        color:'#F9A825',
    },
    regular: {
        color: "white",
        marginTop: '3vh',
    },
    blue: {
        backgroundColor: '#10325F',
        color: 'white',
    },
    kominfoLogo: {
        maxWidth: '9vh',
        marginRight: '3vh',
    },
    trustLogo: {
        maxWidth: '35vh',
        marginLeft: '3vh',
    },
    trustLogoMin: {
        maxWidth: '30vh',
        marginLeft: '3vh',
    }
}))