import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    cardSecondary: {
        marginTop: '15vh',
        padding: '5vh',
        backgroundColor: '#1E1E1E',
    },
    image: {
        maxWidth: '45%',
    },
    navigationLink: {
        textTransform: 'none'
    },
    heading: {
        color:'#F9A825',
    },
    regular: {
        color: "white",
        marginTop: '2vh'
    },
    blue: {
        backgroundColor: '#10325F',
        color: 'white',
        width: '130px',
        marginTop: '2vh',
    }
}))