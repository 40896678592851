import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      logoContainer: {
        // margin: theme.spacing(2),
        margin: 2,
      },
      logo: {
        maxWidth: '30%',
      },
      navigationLink: {
        textTransform: 'none'
    },
    h6: {
      fontSize: 'calc(80% + 0.2vw)'
    },
    textContainer: {
        width: '100vw'
    },
    footer: {
        position: 'static',
        bottom: 0,
        marginBottom: 0,
        marginTop: '5vh',
        overflow: 'hidden',
        width: '100%',
        // height: '35vh',
        backgroundColor: "#121212",
        color: theme.palette.getContrastText('#121212'),
        padding: theme.spacing(2, 0),
    },
    boxMainDescription: {
      display: "flex",
      // border: "1px solid black",
      marginBottom: "-8px",
    },
    descriptionBox: {
      alignItems: "left"
    },
}))