import React from 'react';
import { Box, Typography, Container } from '@material-ui/core';
import useStyles from './style';

const Footer = () => {
    const classes = useStyles();
    return (
        <div>
            <footer className={classes.footer}>
            <Container style={{marginBottom: '2vh'}}>
                <Box
                    component="span" //uses different element but default styling is same
                    m={1}
                    className={`${classes.descriptionBox} ${classes.boxMainDescription}`} >
                        <Typography variant="caption">
                            BIGNET | The First Ka-Band HTS based provider in Indonesia
                        </Typography>
                </Box>
                <Box
                    component="span" //uses different element but default styling is same
                    m={1}
                    className={`${classes.descriptionBox} ${classes.boxMainDescription}`} >
                        <Typography variant="caption">
                            Copyright 2020 PT. BIS DATA INDONESIA
                        </Typography>
                </Box>
                <Box
                    component="span" //uses different element but default styling is same
                    m={1}
                    className={`${classes.descriptionBox} ${classes.boxMainDescription}`} >
                        <Typography variant="caption">
                            All Rights Reserved.
                        </Typography>
                </Box>
            </Container>
                
            </footer>
        </div>
    )
}

export default Footer;