import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      logoContainer: {
        // margin: theme.spacing(2),
        // margin: 2,
      },
      logo: {
        width: '30%',
        marginLeft: '5vh',
        display: 'flex'
      },
      logoMin: {
        width: '40%',
        display: 'flex'
      },
      navigationLink: {
        textTransform: 'none'
    },
}))