import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({ 
    palette: {
        BGColor1: {
            main: "#121212"
        }, 
        BGColor2: {
            main: "#1e1e1e",
        },
		accent1: {
			main: "#10325F"
		},
        accent2: {
			main: "#0D47A1"
		},
        accent3: {
			main: "#F9A825"
		}
    },
    typography: { 
		fontFamily: [ 'Open Sans' ].join(','), 
	}, 
	overrides: {
		MuiButton: {
		  root: {
			textTransform: 'none'
		  },
		}, 
	  },
})

export default theme;