import React, { useEffect } from 'react';
import { Typography, Button, Card, CardContent, Container, Grid } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import useStyles from './style';
import access_denied from '../images/access_denied.png';

import useMediaQuery from '@mui/material/useMediaQuery';

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }));

const GreyCard = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:600px)');

    const useEffect = () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    const MouseOver = (event) => {
        event.target.style.color = '#F9A825';
    }

    const  MouseOut = (event) => {
        event.target.style.background="";
        event.target.style.color="";
    }

    const onClickKembali = () => {
        window.history.back();
    }

    return (
        <div>
            <div style={{marginRight: '3vw', marginLeft: '3vw'}}>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                        <div style={matches === true ? {marginTop: '15vh', padding: '5vh'} : {marginTop: '3vh'}}>
                            <ins className="adsbygoogle"
                            style={{display: 'block'}}
                            data-ad-client="ca-pub-2612235604888173"
                            data-ad-slot="6500648334"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        </div>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Card className={classes.cardSecondary}>
                            <CardContent className={classes.cardContent}>
                                <div>
                                    { matches === true ? 
                                        <img src={access_denied} alt="logo" className={classes.image}/>
                                        :
                                        <img src={access_denied} alt="logo" style={{width:'100%'}}/>
                                    }
                                </div>
                                <Typography variant="h4" className={classes.heading}>
                                    <b>Situs Terlarang!!</b>
                                </Typography>
                                {
                                    matches === true ? 
                                    <Button variant="disabled" style={{width: '50vw'}}>
                                        <Typography variant="subtitle1" className={classes.regular}>
                                            Tidak dapat diakses melalui jaringan karena terindikasi mengandung salah satu unsur <b>Pornografi, Judi, Phising, SARA, dan PROXY.</b>
                                        </Typography>
                                    </Button>
                                    :
                                    <Typography variant="subtitle1" className={classes.regular}>
                                        Tidak dapat diakses melalui jaringan karena terindikasi mengandung salah satu unsur <b>Pornografi, Judi, Phising, SARA, dan PROXY.</b>
                                    </Typography>
                                }
                                
                                <br/>
                                <Button className={classes.blue} 
                                        type="button"
                                        onClick={onClickKembali}
                                        onMouseOver={MouseOver}
                                        onMouseOut={MouseOut}>
                                    <ArrowBackIcon style={{paddingRight: '10'}}/> Kembali
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <div style={matches === true ? {marginTop: '15vh', padding: '5vh'} : {marginTop: '3vh'}}>
                            <ins className="adsbygoogle"
                            style={{display: 'block'}}
                            data-ad-client="ca-pub-2612235604888173"
                            data-ad-slot="6500648334"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        </div>
                    </Grid>
                </Grid>
            
            </div>
        </div>
    )
}

export default GreyCard;