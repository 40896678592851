import React from 'react';
import { Typography, Card, CardContent, Grid } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useStyles from './style';
import kominfo from '../images/logo-kominfo.png';
import trust from '../images/trust.png';
import logo from '../images/kominfo.png';
import useMediaQuery from '@mui/material/useMediaQuery';


const GreyCard = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:600px)');


    return (
        <div>
            <Card className={classes.cardSecondary}>
                <CardContent className={classes.cardContent}>
                    { matches === true ?
                        <div>
                            <img src={logo} alt="logo" className={classes.trustLogo}/>
                            <Typography variant="subtitle1" className={classes.regular}>
                                Jika anda merasa situs ini tidak termasuk ke dalam kategori di atas, silahkan <br/>
                                menghubungi <span style={{color:'#F9A825'}}><a href="mailto:netabuse@bignet.id?subject=Aduan%20konten" style={{color:'#F9A825', textDecoration:'none'}} ><b>netabuse@bignet.id</b></a></span>, dengan <b>subject: Aduan Konten</b>
                            </Typography>
                        </div>
                        :
                        <div>
                            <img src={logo} alt="logo" className={classes.trustLogoMin}/>
                            <Typography variant="subtitle1" className={classes.regular}>
                                Jika anda merasa situs ini tidak termasuk ke dalam kategori di atas, silahkan menghubungi <span style={{color:'#F9A825'}}><a href="mailto:netabuse@bignet.id?subject=Aduan%20konten" style={{color:'#F9A825', textDecoration:'none'}} ><b>netabuse@bignet.id</b></a></span>, dengan <b>subject: Aduan Konten</b>
                            </Typography>
                        </div>

                    }
                </CardContent>
            </Card>
        </div>
    )
}

export default GreyCard;