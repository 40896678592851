import './App.css';
import { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';
import Navbar from './components/Navbar/navbar';
import GreyCard from './components/GreyCard/GreyCard';
import BlueCard from './components/BlueCard/BlueCard';
import Footer from './components/Footer/Footer';

function App() {

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  });

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Navbar />
      <GreyCard />
      {/* Horizontal banner */}
      <ins className="adsbygoogle"
        style={{display:'block'}}
        data-ad-client="ca-pub-2612235604888173"
        data-ad-slot="9098891519"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <BlueCard />
      <Footer />
    </div>
    </ThemeProvider>
  );
}

export default App;
